import React, { useState, useEffect, Fragment} from 'react'
import {useLocation} from 'react-router-dom';
import { Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import BorderColor from '@material-ui/icons/BorderColor';
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import AddTestComponent from './AddTestComponent';
import AddTestUsingFileComponent from './AddTestUsingFileComponent'
import UpdateTestComponent from './UpdateTestComponent';
import Box from '@mui/material/Box';
import {getQualityTestsByWorkspace} from '../services/QualityTestService'
import { runQualityTest } from '../services/QualityTestService';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TablePagination,
  TableFooter
} from '@material-ui/core';
const useStyles = makeStyles((theme) =>({
  tableContainer: {
      borderRadius: 15,
      margin:'auto',
      maxWidth: '95%',
      marginTop: '10px'
  },
  tableheaderCell: {
      fontWeight: 'bold',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.getContrastText(theme.palette.primary.dark)
  },
  name: {
      fontWeight: 'bold',
      color: theme.palette.secondary.dark
  },
  actions: {
      padding: '3px 5px',
  },
  addButton: {
    marginTop:'20px',
    marginRight:'50px'
  },
  loaderWrapper:{
    position:"fixed",
    top:"0",
    left:"0",
    width:"100%",
    height:"100%",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    backgroundColor:"rgba(255, 255, 255, 0.7)",
    color:"#00254d",
    fontFamily: "Arial",
    fontSize: "2rem"
  },
  loader:{
    position:"absolute",
    opacity:"0.7"
  },
  loaderSpinner:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    position:"absolute",
  }

}));

function QualitiesTests() {
  const location = useLocation();
  const idWorkspace = location.state.item.idWorkspace;
  const [qualitiesTests, setQualitiesTests] = useState([]);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [qualityTestToUpdate, setQualityTestToUpdate] = useState(null)
  const [loadingRunTest,setLoadingRunTest] = useState(false)

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const [openQualityTestUpdate, setOpenQualityTestUpdate] = useState(false);
  const handleOpenQualityTestUpdate = () => setOpenQualityTestUpdate(true);
  const handleCloseQualityTestUpdate = () => setOpenQualityTestUpdate(false);

const handleChangePage = (event, newPage) => {
    setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
};

useEffect(() => {
  getQualityTestsByWorkspace(idWorkspace).then((res) => {
      setQualitiesTests(res.data)
  });    
},[idWorkspace,openQualityTestUpdate])

const onUpdateQualityTest = (qualityTest) => {
  const newJsonInput = JSON.parse(qualityTest.jsonInput);
  newJsonInput.tables.forEach( (table) => {
    table.analyzers.forEach( (analyzer) => {
      analyzer.params.forEach( (param) => {
        analyzer[param.name] = param.value
      })
      delete analyzer.params
    })
  })
  qualityTest.jsonInput = newJsonInput
  setQualityTestToUpdate(qualityTest)
  handleOpenQualityTestUpdate()
}

const onRunQualityTest = (idQualityTest) => {
  setLoadingRunTest(true)
  runQualityTest(idQualityTest).then((res) => {
    setLoadingRunTest(false)
    if(res.data)
    {
      toast.success("Test runs successfully", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
        hideProgressBar: true});
    }
    else {
      toast.error("The test failed", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
        hideProgressBar: true});
    }  
  });  
}
return ( 
  <>
<Grid>
  
    <Grid container justifyContent='flex-end' className={classes.addButton}>
      <Box sx={{ mb: 2 }}>
        <Button variant="contained" endIcon={<AddIcon />} onClick={handleOpen} sx={{ mt: 1, mr: 1 }} >
          Add
        </Button>
        <Button variant="contained" endIcon={<AddIcon />} onClick={handleOpen2} sx={{ mt: 1, mr: 7 }} >
          Add using file
        </Button>
      </Box>
    </Grid>
   
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableheaderCell}>Test name</TableCell>
            <TableCell className={classes.tableheaderCell}>Test date</TableCell>
            <TableCell className={classes.tableheaderCell}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {qualitiesTests
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((qualityTest) => (
                <TableRow
                key={qualityTest.idQualityTest}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row" className={classes.name}>
                      {qualityTest.name}
                    </TableCell>
                    <TableCell>
                        <Typography color='primary' variant='subtitle2'>{qualityTest.createdDate}</Typography>
                    </TableCell>
                    <TableCell className={classes.actions}>
                        <IconButton onClick={() => onUpdateQualityTest(qualityTest)}><BorderColor fontSize="medium" /></IconButton>
                        <IconButton onClick={() => onRunQualityTest(qualityTest.idQualityTest)} className={classes.actions}><PlayCircleFilled fontSize="medium" /></IconButton>
                        <IconButton className={classes.actions}><DashboardIcon fontSize="medium" /></IconButton>
                    </TableCell>
                </TableRow>
          ))}
        </TableBody>
        <TableFooter>
            <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                count={qualitiesTests.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableFooter>
      </Table>
    </TableContainer>
    {open &&
    <AddTestComponent 
    open={open} 
    handleClose={handleClose} 
    idworkspace={idWorkspace}
    setQualitiesTests = {setQualitiesTests}
    qualitiesTests = {qualitiesTests}
    />}
    {open2 &&
    <AddTestUsingFileComponent 
    open={open2} 
    handleClose={handleClose2} 
    idworkspace={idWorkspace}
    setQualitiesTests = {setQualitiesTests}
    qualitiesTests = {qualitiesTests}
    />}
    {openQualityTestUpdate &&
    <UpdateTestComponent 
    open={openQualityTestUpdate} 
    idworkspace={idWorkspace}
    handleClose={handleCloseQualityTestUpdate} 
    qualityTest = {qualityTestToUpdate}
    />}
  <ToastContainer />
</Grid>

{loadingRunTest &&  
    <div className={classes.loaderWrapper}>  
      <div className={classes.loader}>
        Please wait a little bit ...
        <div class="d-flex justify-content-center">
          <div class="spinner-grow text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
}
</>
);
}

export default QualitiesTests
