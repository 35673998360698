import React  from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {saveWorkspace, updateWorkspace} from '../services/WorkspaceService';

const schema = yup.object().shape({
  name: yup.string().required(),
  dbDriver: yup.string().required(),
  dbName: yup.string().required(),
  dbPwd: yup.string().required(),
  dbType: yup.string().required(),
  dbUserName: yup.string().required(),
});

const errorMsg = {
    fontSize: 12,
    color: "#ff0000",
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  height: '45%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
  
function SaveWorkspaceComponent({open, handleClose, setWorkspaces, workspaces, workspace}) {

    const { register, handleSubmit, formState: { errors }} = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: workspace.name,
            dbDriver: workspace.dbDriver,
            dbType: workspace.dbType,
            dbName: workspace.dbName,
            dbUserName: workspace.dbUserName,
            dbPwd:workspace.dbPwd,
        }
    });
    const onSubmit = data => {
        if(workspace.idWorkspace){
            data.idWorkspace = workspace.idWorkspace;
            updateWorkspace(data)
            .then( response => {
                setWorkspaces(
                    workspaces.map(item => 
                        item.idWorkspace === response.data.idWorkspace 
                        ? response.data
                        : item 
                    )
                )
            })
        }        
        else{
            saveWorkspace(data)
            .then( response =>{
                setWorkspaces([...workspaces, response.data]);
            });
        }
        handleClose()
    }
  return (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            {workspace.idWorkspace ? <h2> Update a workspace </h2> : <h2> Add a workspace </h2> }
            <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row mt-4" >
                <div className="col">
                    <input type="text" placeholder='Name' className="form-control" name='name' {...register("name")}/>
                    <p style={errorMsg}>{errors.name?.message}</p>
                </div>
                { !workspace.idWorkspace && 
                    <div className="col">
                        <input type="text" placeholder='DB driver' className="form-control" {...register("dbDriver")} name='dbDriver'/>
                        <p style={errorMsg}>{errors.dbDriver?.message}</p>
                    </div>
                }
            </div>
            <div className="row mt-4">
            { !workspace.idWorkspace && 
                <div className="col">
                    <select className="form-control" {...register("dbType")} name='dbType' >
                        <option value="" disabled={true}>Database type</option>
                        <option value="mysql">MySQL</option>
                        <option value="postgresql">PostrgeSQL</option>
                    </select>
                    <p style={errorMsg}>{errors.dbType?.message}</p>
                </div>
            }
                <div className="col">
                    <input type="text" placeholder='DB name' className="form-control" {...register("dbName")} name='dbName'/>
                    <p style={errorMsg}>{errors.dbName?.message}</p>
                </div>
            </div>
            <div className="row mt-4" >
                <div className="col">
                    <input type="text" placeholder='Data user' className="form-control" {...register("dbUserName")} name ='dbUserName'/>
                    <p style={errorMsg}>{errors.dbUserName?.message}</p>
                </div>
                <div className="col">
                    <input type="password" placeholder='Data pwd' className="form-control" {...register("dbPwd")} name ='dbPwd'/>
                    <p style={errorMsg}>{errors.dbPwd?.message}</p>
                </div>
            </div>
            <div className="row" >
                <div className="col mt-5 text-center">
                    <Button variant="contained" size='medium' type='submit' color='success' sx={{minWidth:'150px'}}> {!workspace.idWorkspace ? "Add" : "Update"} </Button>
                </div>
            </div>
            </form>
        </Box>
    </Modal>
  );
}

export default SaveWorkspaceComponent;