import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import DisplayTableComponent from './DisplayTableComponent';
import {getDbTablesAndColumns} from '../services/DataBaseService';
import {saveQualityTest} from '../services/QualityTestService'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75%',
  height: '85%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  overflowY:'scroll',
  p: 4,
};
  
function AddTestComponent({open, handleClose, idworkspace, setQualitiesTests, qualitiesTests}) {
    const analyzersTypes = [
        {
            label: 'PatternMatch',
            params : [  {name:'Column', type:'select'}, {name:'Pattern', type:'input'} ]
        },
        {
            label: 'Compliance',
            params : [  {name:'Column', type:'select'}, { name:'SQLPredicate', type:'input'} ]
        },
        {
            label: 'Completeness',
            params : [  {name:'Column', type:'select'}]
        },
        {
            label: 'Size',
            params : []
        },
        {
            label: 'Mean',
            params : [  {name:'Column', type:'select'}]
        },
        {
            label: 'Correlation',
            params : [  {name:'Column', type:'select'}, {name:'Column2', type:'select'}]
        }
       ]
    const [activeStep, setActiveStep] = useState(0);
    const [errorMsg, setErrorMsg] = useState('');
    const [testName, setTestName] = useState('');
    const [tableInput, setTableInput] = useState({name:'default', dataSource:''})
    const [analyzerInput, setAnalyserInput] = useState({type:'', rules:[]});
    const [analyzers,setAnalyzers] = useState([]);
    const [tables,setTables] = useState([]);
    const [workspaceDb,setWorkspaceDb] = useState([]);
    useEffect(()=>{
        getDbTablesAndColumns(idworkspace)
        .then( (response) => {
            setWorkspaceDb(response.data)
        });

      },[idworkspace]);

    const onChangeAnalyserInput = (i, value) => {
        let newRules = [...analyzerInput.rules]; 
        newRules[i] = value; 
        setAnalyserInput({type: analyzerInput.type, rules: newRules});
    }
    const onInitAnalyzerInput = (val) => {
        let newRules = [...analyzerInput.rules]; 
        analyzersTypes.filter((e) => e.label === val).map((item) => (
            item.params.forEach((param,index) => {
                newRules[index] = '';
            })
        ))
        setAnalyserInput({type: val, rules:newRules})
    }
    
    const onDeleteAnalyzer = (e, index) =>{
        e.preventDefault();
        var tableAnalyzers = [...analyzers];
        tableAnalyzers.splice(index, 1);
        setAnalyzers(tableAnalyzers);
    }

    const onDeleteTable = (e, index) =>{
        e.preventDefault();
        var tableTables = [...tables];
        tableTables.splice(index, 1);
        setTables(tableTables);
    }


    const onAddAnalyzer = (e) => { 
        e.preventDefault();
        setErrorMsg('');
        let newRules=[]
        analyzersTypes.filter((e) => e.label === analyzerInput.type).map((item) => (
            item.params.forEach((param, index) => {
                newRules.push({name: param.name, value: analyzerInput.rules[index]})
            })
        ))
        var analyser = {
            analyzerType: analyzerInput.type,
            params: newRules
        }
        setAnalyzers([...analyzers, analyser])
        setAnalyserInput({type:'', rules:[]});
    }

    const onAddNewTable = () =>{
        var table = {
            tableName: tableInput.name,
            dataSource: tableInput.dataSource === '' ? 'default' : tableInput.dataSource,
            analyzers: analyzers
        }
        setTables([...tables, table]);
        setTableInput({name:'default', dataSource:''});
        setAnalyzers([]);
        handleBack();        

    }

    const handleNext = (StepIndex) => {
        var emptyField = false;
        if(StepIndex < 2){
            switch (StepIndex) {
                case 0:
                    if(testName === '') emptyField = true;
                break;
                default:
                    if(tableInput.name === "default") emptyField = true;
                    if(tables.length > 0) emptyField=false;
            }
            if(emptyField) setErrorMsg('please fill in this field');
            else {
                setErrorMsg('');
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }    
        }
        else {
            if(tableInput.name !== "default" && analyzers.length === 0) setErrorMsg('please add some rules');
            else
            {
                var qualityTest = {
                    idWorkspace: idworkspace,
                    inputObject: {
                        tables: tables
                    },
                    name: testName
                }
                console.log(JSON.stringify(qualityTest));
                saveQualityTest(qualityTest)
                .then( (res) => {
                    setQualitiesTests([...qualitiesTests, res.data])
                })
                handleClose()
            }

        }
                
    };

    const handleBack = () => {
        setErrorMsg('');
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const steps = [
        {
          label: 'Create a test',
          description: <Box><form>
                            <div className="row mb-4">
                                <div className="col">
                                    <input type="text" placeholder='Name' className="form-control" value={testName} onChange={e =>setTestName(e.target.value)}/>
                                    <span className="text-danger">{errorMsg}</span>
                                </div>
                            </div>
                        </form></Box>,
        },
        {
          label: 'Add Table',
          description: <Box><form>
                        <div className="row">
                            <div className="col">
                                <select className="form-control" value={tableInput.name} required
                                onChange={e =>setTableInput(({name: e.target.value, column:tableInput.column, dataSource:tableInput.dataSource}))}
                                >
                                    <option value="default" disabled={true}>Table name</option>
                                    {
                                        workspaceDb.map((item, index) => (
                                            <option key={index} value={item.dbTableName}>{item.dbTableName}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col">
                                <input type="text" placeholder='Data source' value={tableInput.dataSource} className="form-control"  
                                onChange={e =>setTableInput(({name: tableInput.name, column:tableInput.column, dataSource:e.target.value}))} 
                                />
                            </div>
                        </div>
                        <span className="text-danger">{errorMsg}</span>
                    </form>
                    {tables.length>0 ?
                        <DisplayTableComponent 
                            data={tables}
                            columns={["Table name","Data Source", "Nbr of rule", "Analyzers"]}
                            DeleteMethod = {onDeleteTable}
                            type = 'table'
                        />
                    :
                    null
                    }
                    </Box>,
        },
        {
          label: 'Add analyzer',
          description: <Box><form onSubmit={(e) => onAddAnalyzer(e)}>
                        <div className="row">
                            <div className="col">
                                <select className="form-control" required value={analyzerInput.type}
                                onChange={(e)=> onInitAnalyzerInput(e.target.value)}
                                >
                                    <option value="" >Analyzer type</option>
                                        {
                                            analyzersTypes.map((item, index) => (
                                                <option key={index} value={item.label}>{item.label}</option>
                                            ))
                                        }
                                </select>
                            </div>
                            {
                                analyzersTypes.filter((e) => e.label === analyzerInput.type).map((item) => (
                                    item.params.map((param, index) => (
                                    <div className="col" key={index}>
                                        {param.type === 'input' ?
                                            <input type="text" placeholder={param.name} value={analyzerInput.rules[index]} className="form-control" required
                                            onChange={e => onChangeAnalyserInput(index,e.target.value)}
                                            />
                                        :
                                            <select className="form-control" required value={analyzerInput.rules[index]} 
                                            onChange={e => onChangeAnalyserInput(index,e.target.value)}
                                            >
                                                <option value="" >{param.name}</option>
                                                {workspaceDb.filter((e) => e.dbTableName === tableInput.name).map((item) => (
                                                    item.dbTableColumns.map((column, index) => (
                                                        <option key={index} value={column}>{column}</option>
                                                    ))
                                                ))}
                                            </select>
                                        }
                                        </div>
                                    ))
                                ))
                            }
                            <div className="col-1">
                                <Button type='submit'> <AddIcon fontSize="medium" /></Button>
                            </div>
                        </div>
                    <span className="text-danger">{errorMsg}</span>
                </form>
                {analyzers.length>0 ?
                        <DisplayTableComponent 
                            data={analyzers}
                            columns={["analyzerType", "Column", "SQLPredicate","Column2", "Pattern"]}
                            DeleteMethod = {onDeleteAnalyzer}
                            type = 'analyzer'

                        />
                    :
                    null
                }
                </Box>,
        },
      ];

  return (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
            <Step key={step.label}>
                <StepLabel
                optional={
                    index === 2 ? (
                    <Typography variant="caption">Last step</Typography>
                    ) : null
                }
                >
                {step.label}
                </StepLabel>
                <StepContent>
                    {step.description}
                    <Box sx={{ mb: 2 }}>
                        <Button
                            variant="contained"
                            onClick={() => handleNext(index)}
                            sx={{ mt: 1, mr: 1 }}
                            
                        >
                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                        </Button> 
                        {index === steps.length -1 ?
                        <Button
                            disabled={ analyzers.length === 0}
                            onClick={() =>onAddNewTable()}
                            sx={{ mt: 1, mr: 1 }}
                        >
                            Add table
                        </Button>
                        : 
                        null
                        }
                        <Button
                            disabled={index === 0}
                            onClick={() => handleBack()}
                            sx={{ mt: 1, mr: 1 }}
                        >
                            Back
                        </Button>
                        
                    </Box>
                </StepContent>
            </Step>
            ))}
        </Stepper>
        </Box>
      </Modal>
  );
}

export default AddTestComponent;