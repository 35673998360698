import MaterialTable from 'material-table';
import React, { useState } from 'react'
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import InfoIcon from '@mui/icons-material/Info';
import { forwardRef } from 'react';
import QualityTestAnalyzerUpdate from '../QualityTestAnalyzerUpdate';
const panelDetailsStyle = {
  display: "flex",
  justifyContent: "center",
  marginBottom:"15px",
  marginTop:"15px",
  fontFamily: "Arial",
};

function TableMaterialUI({tableData, setTableData, columns}) {
  const tableIcons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <InfoIcon {...props} ref={ref} />),
  }

  
  const [analyzers, setAnalyzers] = useState([])
  const setRowAnalyzers = (actualRow,analyzers) =>{
    actualRow.analyzers = [...analyzers] 
  }
      return (
          <MaterialTable columns={columns} data={tableData} icons={tableIcons} 
            editable={{
              isDeletable: rowData => tableData.length >1,
              onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
                const updatedData = [...tableData]
                updatedData[oldRow.tableData.id] = newRow
                setTableData(updatedData)
                setTimeout(() => resolve(), 500)
              }),
              onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
                const updatedData = [...tableData]
                updatedData.splice(selectedRow.tableData.id, 1)
                setTableData(updatedData)
                setTimeout(() => resolve(), 1000)
              })
            }}
            options={{
              pageSizeOptions: [2, 5, 8, 10], pageSize: 5,
              paginationType: "stepped", showFirstLastPageButtons: false,
              actionsColumnIndex: -1,
              search: false,
              sorting: false,
              paging: false,
              headerStyle: { background: "#0275d8",color:"#fff" ,height: 40,  maxHeight: 40,  padding: 0,},
              detailPanelType: "single",
              showTitle: false,
              toolbar:false,
              rowStyle: {height: 20,maxHeight: 20,padding: 0, background: "#F5F7FA"},
            }}  
            detailPanel={
              [
              rowData => ({
                tooltip: 'More details',
                render: rowData => {
                  console.log(analyzers);
                  return (
                    <div style={panelDetailsStyle}>
                      <QualityTestAnalyzerUpdate tableData = {rowData.analyzers} setTableData={setAnalyzers} actualRow={rowData} setRowAnalyzers={setRowAnalyzers}/>
                    </div>  
                  )                               
                },
              })
            ]}   
               
            />
      );
    }
    

export default TableMaterialUI