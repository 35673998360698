import React from 'react';
import Footer from './components/Layout/Footer';
import Header from './components/Layout/Header';
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./services/Keycloak"
import SecuredPage from './pages/SecuredPage';
import PrivateRoute from './helpers/PrivateRoute';
function App(){
    return (
      <div>
     <ReactKeycloakProvider authClient={keycloak}>
     <Header/>
       <PrivateRoute>
          <SecuredPage />
        </PrivateRoute>
      </ReactKeycloakProvider>
      <Footer/>
      </div>

    )
  }
export default App;


