import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Workspaces from '../components/Workspaces';
import QualitiesTests from '../components/QualitiesTests';
import TableMaterialUI from '../components/toUse/TableMaterialUI';
function SecuredPage(){
    return (
    <BrowserRouter>
            <Routes>
             <Route path="/test" element={<TableMaterialUI />} />
              <Route path="/" element={<Workspaces />} />
              <Route path="/:id/QualitiesTests" element={<QualitiesTests />} />
              <Route path="*" element={
                  <main style={{ padding: "1rem" }}>
                    <p>There's nothing here!</p>
                  </main>
                }
              />
            </Routes>
          </BrowserRouter>
    )
  }
export default SecuredPage;


