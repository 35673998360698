import MaterialTable from 'material-table';
import React, { Fragment } from 'react'
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import InfoIcon from '@mui/icons-material/Info';
import { forwardRef } from 'react';
const titleStyle = {
  fontFamily: 'Arial', 
  fontSize: "15px",
  fontWeight: "400", 
  margin: "0 0 20px", 
  textAlign: "center" 
}
function QualityTestAnalyzerUpdate({tableData, setTableData, actualRow, setRowAnalyzers}) {
    const tableIcons = {
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <InfoIcon {...props} ref={ref} />),
      }
    const columns = [ 
        { title: "Analyzer type", field: "analyzerType", align: "center" , editable: 'never', disableSortBy: true },
        { title: "Column", field: "Column", align: "center" , disableSortBy: true , cellStyle: { minWidth: 200 }},
        { title: "Pattern", field: "Pattern", align: "center" , disableSortBy: true , cellStyle: { minWidth: 300 }},
        { title: "SQLPredicate", field: "SQLPredicate", align: "center", disableSortBy: true , cellStyle: { minWidth: 300 }},
        { title: "Column2", field: "Column2", align: "center" , disableSortBy: true , cellStyle: { minWidth: 300 }},
      ]

      return (
        <Fragment>
        {actualRow.analyzers.length > 0 ?
        <MaterialTable columns={columns} data={tableData} icons={tableIcons}
        cellEditable={{
          onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
            return new Promise((resolve, reject) => {
              const clonedData = [ ...tableData ]
              clonedData[rowData.tableData.id][columnDef.field] = newValue
              setTableData(clonedData)
              
              setTimeout(resolve, 1000);
            });
          }
        }}
        editable={{
          
          onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
            const updatedData = [...tableData]
            updatedData.splice(selectedRow.tableData.id, 1)
            setTableData(updatedData)
            setTimeout(() => resolve(), 1000)
            setRowAnalyzers(actualRow, updatedData)
          })
        }}
        options={{
          paging: false,
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          toolbar:false,
          sorting: false,
          headerStyle: {
            height: 20,
            maxHeight: 20,
            padding: 0,
            background: "#000137",
            color:"#fff"
        },
        rowStyle: {
            height: 20,
            maxHeight: 20,
            padding: 0
        },
        }}
        />
        :
        <p style={titleStyle}>No analyzer applied to this table</p>
      }
      </Fragment>
      );
    }
    

export default QualityTestAnalyzerUpdate