import React, { useState } from 'react'
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import { 
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TableFooter
  } from '@material-ui/core';
function DisplayTableComponent({data, DeleteMethod, columns, type}) {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(4);
    const handleChangePage = (event, newPage) => { setPage(newPage); };
    const handleChangeRowsPerPage = (event) => { 
        setRowsPerPage(event.target.value); 
        setPage(0);
    };

  return (
    <TableContainer >
        <Table size="small" aria-label="a dense table">
            <TableHead>
                <TableRow>
                    {columns.map( (column, index) => (
                       <TableCell key={index} align="center">{column}</TableCell> 
                    ))
                }
                    <TableCell align="center">Actions</TableCell>
                </TableRow>
            </TableHead>
                <TableBody>
                {type === 'analyzer'?
                data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                    <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                            <TableCell align="center"> {row.analyzerType}</TableCell>
                            {
                                columns.slice(1).map( (col,indexCol) => (
                                        <TableCell key={indexCol} align="center"> 
                                            {row.params.map((e, i) => (
                                                e.name === col ? e.value : ''
                                                )
                                            )}
                                        </TableCell>
                                        )
                                    )
                                }
                            <TableCell align="center">
                            <IconButton onClick={(e) => DeleteMethod(e, index)}> <DeleteIcon fontSize="medium"/></IconButton>
                        </TableCell>
                    </TableRow>
                    
                    ))
                    :
                data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                        
                       <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                        <TableCell align="center">{row.tableName}</TableCell>
                        <TableCell align="center">{row.dataSource}</TableCell>
                        <TableCell align="center">{row.analyzers.length}</TableCell>
                        <TableCell align="center">
                            {row.analyzers.map( (anayzer) => anayzer.analyzerType + ' , ')}
                        </TableCell>
                        <TableCell align="center">
                        <IconButton onClick={(e) => DeleteMethod(e, index)}> <DeleteIcon fontSize="medium"/></IconButton>
                    </TableCell>
                    </TableRow>
                    
                    ))
                }
                </TableBody>
                <TableFooter>
                    <TablePagination
                        rowsPerPageOptions={[4, 6, 8]}
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableFooter>
            </Table>
        </TableContainer>
  )
}

export default DisplayTableComponent;
