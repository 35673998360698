import React from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

const style = {
    position: 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    height: '20%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };


function DeleteWorkspaceComponent({open, handleDeleteTrue, handleDeleteFalse}) {
  return (
    <Modal
        open={open}
        onClose={handleDeleteFalse}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <h3>Delete workspace</h3>
            <p>You sure you wanna delete this workspace?</p>
            <Button onClick={handleDeleteTrue} variant="contained" color="success" sx={{ left: '20%' }}>
                Confirm
            </Button>
            <Button onClick={handleDeleteFalse} variant="contained" color="error" sx={{ left: '40%' }} >
                Cancel
            </Button>
        </Box>
   </Modal>
  );
}
export default DeleteWorkspaceComponent;