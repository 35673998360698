import React, {useState} from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TableMaterialUI from './toUse/TableMaterialUI';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Button from '@mui/material/Button';
import {updateQualityTest} from '../services/QualityTestService'
const schema = yup.object().shape({
  name: yup.string().required(),
});
const errorMsg = {
    fontSize: 12,
    color: "#ff0000",
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85%',
    height: '85%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflowY:'scroll',
    p: 4,
  };

function SaveWorkspaceComponent({open, handleClose, qualityTest, idworkspace}) {
    const { register, handleSubmit, formState: { errors }} = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: qualityTest.name,
        }
    });
    const [qualityTestTables, setQualityTestTables] = useState(qualityTest.jsonInput.tables)

    const qualityTestTablesColums = [ 
        { title: "Table name", field: "tableName", align: "center" },
        { title: "Data source", field: "dataSource", align: "center" },
        { title: "analyzers", field: "analyzers", align: "center", hidden: true}
    ]
    
    const onSubmit = data => {
        var newQualityTestTables = { tables:[] }
        qualityTestTables.forEach((table) => {
            var newTable = { dataSource: table.dataSource, tableName: table.tableName, analyzers:[] }
            table.analyzers.forEach( (analyzer) => {
                var newAnalyzer = { analyzerType: analyzer.analyzerType, params: [] }
                Object.keys(analyzer).forEach( (param) => {
                    if( (param !== "analyzerType") && (param !== "tableData") )
                    {
                        var newParam = {
                            name : param,
                            value: analyzer[param]
                        }
                        newAnalyzer.params.push(newParam)
                    }
                })
                newTable.analyzers.push(newAnalyzer)
            })
            newQualityTestTables.tables.push(newTable)
        })

        var newQualityTest = {
            idWorkspace: idworkspace,
            idQualityTest: qualityTest.idQualityTest,
            name: data.name,
            inputObject:  newQualityTestTables
        }
        updateQualityTest(newQualityTest)
        .then( (response) => {
            console.log("return");
            console.log(response.data);
        });
        handleClose()
    }

  return (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mt-4" >
                    <div className="col">
                        <input type="text" placeholder='Name' className="form-control" name='name' {...register("name")}/>
                        <p style={errorMsg}>{errors.name?.message}</p>
                    </div>
                </div>
                
                <TableMaterialUI 
                    tableData = {qualityTestTables}
                    setTableData = {setQualityTestTables}
                    columns = {qualityTestTablesColums}                    
                />
                <div className="row" >
                    <div className="col mt-5 text-center">
                        <Button variant="contained" size='medium' type='submit' color='success' sx={{minWidth:'150px'}}> Update </Button>
                    </div>
                </div>   
            </form>         
        </Box>
    </Modal>
  );
}

export default SaveWorkspaceComponent;