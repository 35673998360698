import React, { useState} from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {saveQualityTest} from '../services/QualityTestService'

const schema = yup.object().shape({
  name: yup.string().required(),
  files: yup.mixed().test('required', 'Please select a file', value => {
    return value && value.length;
  }),
  
});

const errorMsg = {
  fontSize: 12,
  color: "#ff0000",
  margin: 0,
  padding: 0
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    height: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };


function AddTestUsingFileComponent({open, handleClose, idworkspace, setQualitiesTests, qualitiesTests}) {
 
  const { register, handleSubmit, formState: { errors }, reset} = useForm({
    resolver: yupResolver(schema)
  });
  const [invalidFile, setInvalidFile] = useState("");

  const onValidateJsonFile = (obj) => {
    let errorMsg = "";
    if(!Object.keys(obj).length) errorMsg +="The json file is empty";
    else {
      if(! obj.hasOwnProperty("tables")) errorMsg += "Json file must have array";
      else {
        if(obj.tables.length === 0) errorMsg +="There is no tables in json file. tablesPlease add some tables";
        obj.tables.forEach((t) => {
        if( t.hasOwnProperty("dataSource") && t.hasOwnProperty("tableName") && t.hasOwnProperty("analyzers") )
        {
          if(t.dataSource === "" || t.tableName === "" || t.analyzers.length === 0) errorMsg += "The table data is null (table name, data source , analyzer of the table in json file).";
          t.analyzers.forEach( (a) => {
            if(a.hasOwnProperty("analyzerType") && a.hasOwnProperty("params"))
            {
              switch (a.analyzerType) {
              case "Size":
                if(a.params.length !== 0) errorMsg +="The analyzer Size has no params.";
              break;
              case "PatternMatch":
                if(a.params.length !== 2) errorMsg += "the analyzer PatternMatch must have 2 params.";
                else {
                  if(a.params[0].hasOwnProperty("name") && a.params[1].hasOwnProperty("name") && a.params[0].hasOwnProperty("value") && a.params[1].hasOwnProperty("value"))
                  {
                    if(a.params[0].name !== 'Column' || a.params[1].name !== 'Pattern') errorMsg += "The analyzer PatternMatch has 2 params (Column, Pattern).";
                  }
                  else{
                    errorMsg += "Undefined params ( name, value) in the analyzer PatternMatch.";
                  }
                }
              break;
              case "Compliance":
                if(a.params.length !== 2) errorMsg += "the analyzer Compliance must have 2 params.";
                else {
                  if(a.params[0].hasOwnProperty("name") && a.params[1].hasOwnProperty("name") && a.params[0].hasOwnProperty("value") && a.params[1].hasOwnProperty("value"))
                  {
                    if(a.params[0].name !== 'Column' || a.params[1].name !== 'SQLPredicate') errorMsg += "The analyzer Compliance has 2 params (Column, SQLPredicate).";
                  }
                  else{
                    errorMsg += "Undefined params ( name, value) in the analyzer Compliance.";
                  }
                }
              break;
              case "Completeness":
                if(a.params.length !== 1) errorMsg += "the analyzer Completeness must have 1 params.";
                else {
                  if(a.params[0].hasOwnProperty("name") && a.params[0].hasOwnProperty("value"))
                  {
                    if(a.params[0].name !== 'Column') errorMsg += "The analyzer Completeness has 1 params (Column).";
                  }
                  else{
                    errorMsg += "Undefined params ( name, value) in the analyzer Completeness.";
                  }
                }
              break;
              case "Mean":
                if(a.params.length !== 1) errorMsg += "the analyzer Mean must have 1 params.";
                else {
                  if(a.params[0].hasOwnProperty("name") && a.params[0].hasOwnProperty("value"))
                  {
                    if(a.params[0].name !== 'Column') errorMsg += "The analyzer Mean has 1 params (Column).";
                  }
                  else{
                    errorMsg += "Undefined params ( name, value) in the analyzer Mean.";
                  }
                }
              break;
              case "Correlation":
                if(a.params.length !== 2) errorMsg += "the analyzer Correlation must have 2 params.";
                else {
                  if(a.params[0].hasOwnProperty("name") && a.params[1].hasOwnProperty("name") && a.params[0].hasOwnProperty("value") && a.params[1].hasOwnProperty("value"))
                  {
                    if(a.params[0].name !== 'Column' || a.params[1].name !== 'Column2') errorMsg += "The analyzer Correlation has 2 params (Column, Column2).";
                  }
                  else{
                    errorMsg += "Undefined params ( name, value) in the analyzer Correlation.";
                  }
                }
              break;
              default:
                errorMsg += "Invalid anlyazer type.";
              break;
              }
            }
            else {
              errorMsg +="Undefined data for analyzer (analyzerType, params)";
            }
          });
        }
        else {
          errorMsg +="Undefined data for table (data source, table name, and analyzers)";
        }
        });
      }
      
    }
    return errorMsg;
  }   

  const onAddTest = (testName, jsonFile) =>{
    var qualityTest = {
      idWorkspace: idworkspace,
      inputObject: jsonFile,
      name: testName
    } 
    saveQualityTest(qualityTest)
    .then( (res) => {
        setQualitiesTests([...qualitiesTests, res.data])
    })
  }
  const onSubmit = data => {
    if(data.files.length > 0)
    {
      if(data.files[0].name.split('.').pop() !== "json") {
        setInvalidFile("The file must be Json");
      }
      else {
        const fileReader = new FileReader();
        fileReader.readAsText(data.files[0]);
        fileReader.onload = (e) => {
          try{
            let errorMsg = onValidateJsonFile(JSON.parse(e.target.result));
            if(errorMsg === ""){
              onAddTest(data.name, JSON.parse(e.target.result));
              handleClose()
            }
            else {
              setInvalidFile(errorMsg);
            }
          } catch (e) {
            setInvalidFile("The file must contains Json Object")
          }
        };
      }

      reset({name: data.name, files: ""});
    }
  }
  return (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <h1>Add Test using JSON file</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mt-4">
                    <input type="text" placeholder='TestName' className="form-control" name='name' {...register("name")}/>
                    <p style={errorMsg}>{errors.name?.message}</p>
                </div>
                <div className="row mt-4">
                    <input id="files" type="file" accept=".json" className="form-control" name='files' {...register("files")}/>
                    <p style={errorMsg}>{errors.files?.message}</p>
                </div>
                  {invalidFile.split('.').map( (item , index) => (
                    <p key={index} style={errorMsg}>{item}</p>
                  ))
                  }
                <div className="col mt-5 text-center">
                    <Button variant="contained" size='medium' type='submit' color='success' sx={{minWidth:'150px'}}> Add </Button>
                </div>

            </form>
        </Box>
   </Modal>
  );
}
export default AddTestUsingFileComponent;