import React from 'react'
import octoLogo from '../../images/octoLogo.jpg'
import { useKeycloak } from "@react-keycloak/web";

function Header() {

  const { keycloak } = useKeycloak();

    return (
        <>
  <nav className="navbar navbar-expand-lg navbar-light bg-light">
    <div className="container-fluid">
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <a className="navbar-brand mt-2 mt-lg-0" href="#!">
          <img
            src={octoLogo}
            height={50}
            alt="MDB Logo"
          />
        </a>
      </div>
      {!keycloak.authenticated && (
        <div className="d-grid gap-3 d-md-flex justify-content-md-end">
            <button type="button" className="btn btn-outline-primary me-md-2"
              onClick={() => keycloak.login()}>Login</button>
        </div>
       )}
       {!!keycloak.authenticated && (
          <div className="d-grid gap-3 d-md-flex justify-content-md-end">
            <button type="button" className="btn btn-outline-primary me-md-2"
              onClick={() => keycloak.logout()} >Logout ({keycloak.tokenParsed.sub}) </button>
          </div>
        )}
    </div>
  </nav>
</>

    )
}

export default Header