import React from 'react'

function Footer() {

    return (
        <footer className="bg-light text-center text-black" 
            style={{
                position: "fixed",
                left: 0,
                bottom: 0,
                right: 0,
            }}
        >
            <div className="container p-1 pb-0">
                <div>
                    <p className="pt-1">
                        <strong>For more informations</strong>
                    </p>
                </div>
                <section className="mb-3">
                    <a
                        className="btn btn-primary btn-floating m-1"
                        style={{ backgroundColor: "#3b5998" }}
                        href="#!"
                        role="button"
                    >
                        <i className="fab fa-facebook-f" />
                    </a>
                    <a
                        className="btn btn-primary btn-floating m-1"
                        style={{ backgroundColor: "#55acee" }}
                        href="#!"
                        role="button"
                    >
                        <i className="fab fa-twitter" />
                    </a>
                    <a
                        className="btn btn-primary btn-floating m-1"
                        style={{ backgroundColor: "#dd4b39" }}
                        href="#!"
                        role="button"
                    >
                        <i className="fab fa-google" />
                    </a>
                    <a
                        className="btn btn-primary btn-floating m-1"
                        style={{ backgroundColor: "#ac2bac" }}
                        href="#!"
                        role="button"
                    >
                        <i className="fab fa-instagram" />
                    </a>
                    <a
                        className="btn btn-primary btn-floating m-1"
                        style={{ backgroundColor: "#0082ca" }}
                        href="#!"
                        role="button"
                    >
                        <i className="fab fa-linkedin-in" />
                    </a>
                    <a
                        className="btn btn-primary btn-floating m-1"
                        style={{ backgroundColor: "#333333" }}
                        href="#!"
                        role="button"
                    >
                        <i className="fab fa-github" />
                    </a>                    
                </section>
   
            </div>
        </footer>

    )
}

export default Footer