import React, { useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {getWorkspaces, deleteWorkspace} from '../services/WorkspaceService';
import IconButton from "@material-ui/core/IconButton";
import BorderColor from '@material-ui/icons/BorderColor'
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Grid } from "@material-ui/core";
import Button from '@mui/material/Button';
import SaveWorkspaceComponent from './SaveWorkspaceComponent';
import DeleteWorkspaceComponent from './DeleteWorkspaceComponent';

import { 
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    TablePagination,
    TableFooter
 } from '@material-ui/core';
const useStyles = makeStyles((theme) =>({
    tableContainer: {
        borderRadius: 15,
        margin:'auto',
        maxWidth: '95%',
        marginTop: '10px'
        
    },
    tableheaderCell: {
        fontWeight: 'bold',
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.getContrastText(theme.palette.primary.dark)
    },
    name: {
        fontWeight: 'bold',
        color: theme.palette.secondary.dark
    },
    status: {
        fontWeight: 'bold',
        fontSize: '0.75rem',
        color: 'white',
        backgroundColor: 'grey',
        borderRadius: 8,
        padding: '3px 10px',
        display: 'inline-block'
    },
    actions: {
        padding: '3px 5px',
    }

}));

function Workspaces() {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [workspaces, setWorkspaces] = useState([]);
    const [workspaceForSave, setWorkspaceForSave] = useState({name: "", dbDriver: "", dbType:"", dbName: "", dbUserName: "", dbPwd:""});
    const [popup, setPopup] = useState({show: false, id: null});
    let navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChangePage = (event, newPage) => {setPage(newPage);};
    const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(+event.target.value);
            setPage(0);
        };
    
    useEffect(() => {
        getWorkspaces().then((res) => {
            setWorkspaces(res.data)
        });    
      },[])

   
    const GoToSaveWorkspace = (item) =>{
        if(item === null) setWorkspaceForSave({name: "", dbDriver: "", dbType:"", dbName: "", dbUserName: "", dbPwd:""});
        else setWorkspaceForSave(item)
        handleOpen();
    }
    
    const GoToWorkspaceInfo = (workspace) => {
        navigate(workspace.idWorkspace+'/QualitiesTests', { state: {item: workspace} });
    }

    const GoToDeleteWorkspace = (idW) => {
        setPopup({show: true, id: idW});
    }

    const handleDeleteTrue = () => {
        if (popup.show && popup.id) {
        deleteWorkspace(popup.id)
        .then(res => {
            setWorkspaces(workspaces.filter(item => item.idWorkspace !== popup.id))
        })
        setPopup({show: false, id: null});
        }
    };
    
    const handleDeleteFalse = () => {
        setPopup({show: false, id: null});
    };
    

  return (
    <Grid>
  
    <Grid container justifyContent='flex-end' className={classes.addButton}>
        <Button variant="contained" endIcon={<AddIcon />} sx={{ mt: 1, mr: 7 }} onClick={() => GoToSaveWorkspace(null)}>
          Add
        </Button>
    </Grid>

    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableheaderCell}>Name</TableCell>
            <TableCell className={classes.tableheaderCell}>Date</TableCell>
            <TableCell className={classes.tableheaderCell}>DB type</TableCell>
            <TableCell className={classes.tableheaderCell}>DB name</TableCell>
            <TableCell className={classes.tableheaderCell}>DB driver</TableCell>
            <TableCell className={classes.tableheaderCell}>Actions</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {workspaces
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((workspace) => (
                <TableRow
                key={workspace.idWorkspace}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row">
                        <Typography className={classes.name}>{workspace.name}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography color='primary' variant='subtitle2'>{workspace.createdDate}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography color='textSecondary' variant='body2'>{workspace.dbType}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography color='textSecondary' variant='body2'>{workspace.dbName}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography color='textSecondary' variant='body2'>{workspace.dbDriver}</Typography>
                    </TableCell>
                    <TableCell>
                        <IconButton onClick={() => GoToSaveWorkspace(workspace)}>
                            <Typography className={classes.actions}> <BorderColor fontSize="medium" /></Typography>
                        </IconButton>
                        <IconButton onClick={() => GoToWorkspaceInfo(workspace)}>
                            <Typography className={classes.actions}> <InfoIcon fontSize="medium" /></Typography>
                        </IconButton>
                        <IconButton onClick={() =>GoToDeleteWorkspace(workspace.idWorkspace)}>
                            <Typography className={classes.actions}> <DeleteIcon fontSize="medium" /></Typography>
                        </IconButton>
                    </TableCell>
                </TableRow>
          ))}
        </TableBody>
        <TableFooter>
            <TablePagination
                rowsPerPageOptions={[2, 10, 20, 30]}
                count={workspaces.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableFooter>
      </Table>
    </TableContainer>
    {open &&
    <SaveWorkspaceComponent 
        open={open} 
        handleClose={handleClose}
        setWorkspaces = {setWorkspaces}
        workspaces = {workspaces}
        workspace = {workspaceForSave}
    />
    }
    {popup.show && 
    <DeleteWorkspaceComponent
      open = {popup.show}
      handleDeleteTrue={handleDeleteTrue}
      handleDeleteFalse={handleDeleteFalse}
    />
    }
    </Grid>
  );
}
  
export default Workspaces