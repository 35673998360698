import axios from "axios";

const QUALITYTEST_API_BASE_URL=window.API_URL +"qualityTest";


    export const getQualityTestsByWorkspace = async (idWorkspace) => {
        return await axios.get(QUALITYTEST_API_BASE_URL+"/"+idWorkspace);
    }

    export const saveQualityTest = async (qualityTest) => {
        return await axios.post(QUALITYTEST_API_BASE_URL,qualityTest);
    }

    export const updateQualityTest = async (qualityTest) => {
        return await axios.put(QUALITYTEST_API_BASE_URL,qualityTest)
    }

    export const runQualityTest = async (id) => {
        return await axios.post(QUALITYTEST_API_BASE_URL+`/run`, null, { params: {id}});
    }
