import axios from "axios";

const WORKSPACE_API_BASE_URL=window.API_URL + "workspace";

    export const getWorkspaces = async () => {
        let res = await axios.get(WORKSPACE_API_BASE_URL);
        return res;
    };

    export const saveWorkspace = async (workspace) => {
        let res = await axios.post(WORKSPACE_API_BASE_URL,workspace);
        return res;
    };
    export const getWorkspace = async (workspaceId) => {
        let res = await axios.get(WORKSPACE_API_BASE_URL+"/"+workspaceId);
        return res;
    };

    export const updateWorkspace = async (workspace) => {
        let res = await axios.put(WORKSPACE_API_BASE_URL,workspace);
        return res;
    }

    export const deleteWorkspace = async (idWorkspace) => {
        return await axios.delete(WORKSPACE_API_BASE_URL+"/"+idWorkspace)
    }
